import { Options } from "vue-class-component";
import BaseFunction from "@/mixins/BaseFunction";

@Options({
    name: "flowable-setting-header-setting",
    props: {
        modelValue: String,
    },
    emits: ["update:modelValue", "viewXml", "preview", "publish"],
})
export default class HeaderSetting extends BaseFunction {
    private modelValue: string;

    public to(selected: any) {
        this.$emit("update:modelValue", selected);
    }
    public viewXml() {
        this.$emit("viewXml");
    }
    public preview() {
        this.$emit("preview");
    }
    public publish() {
        this.$emit("publish");
    }
    public btnThemes(cur: string): string {
        return this.modelValue === cur ? "btn-themes" : "btn-default";
    }
    get diagramQuery(): any {
        return this.$store.getters["flowable/diagramQuery"];
    }
    set diagramQuery(design: any) {
        this.$store.dispatch("flowable/setDiagramQuery", design).then();
    }
    get setup(): any {
        return this.$store.getters["flowable/design"];
    }
    set setup(design: any) {
        this.$store.dispatch("flowable/setDesign", design).then();
    }
    get formName() {
        return this.setup.formName;
    }
}
