import { Options } from "vue-class-component";
import BaseFunction from "@/mixins/BaseFunction";
import FlowableLayoutHeaderSetting from "@/modules/flowable/setting/header-setting/header-setting.vue";
import FlowableLayoutBaseSetting from "@/modules/flowable/setting/base-setting/base-setting.vue";
import FlowableLayoutFormSetting from "@/modules/flowable/setting/form-setting/form-setting.vue";
import FlowableLayoutProSetting from "@/modules/flowable/setting/pro-setting/pro-setting.vue";
import FlowableLayoutProcessDesign from "@/modules/flowable/process-design/process-design.vue";
import API from "@/api/flowable/process-design";

// 折叠代码
import "codemirror/addon/fold/foldgutter.css";
import "codemirror/addon/fold/foldcode.js";
import "codemirror/addon/fold/foldgutter.js";
import "codemirror/addon/fold/brace-fold.js";
import "codemirror/addon/fold/xml-fold.js";
import "codemirror/addon/fold/indent-fold.js";
import "codemirror/addon/fold/markdown-fold.js";
import "codemirror/addon/fold/comment-fold.js";

import "codemirror/mode/xml/xml.js";
import "codemirror/mode/http/http.js";
import "codemirror/mode/javascript/javascript.js";
import "codemirror/theme/tomorrow-night-eighties.css";
import Codemirror from "codemirror-editor-vue3";
import { EditorConfiguration } from "codemirror";
import DefaultNodeProps from "@/modules/flowable/common/process/DefaultNodeProps";

@Options({
    name: "app-flowable-process-design",
    components: {
        Codemirror,
        "flowable-layout-header-setting": FlowableLayoutHeaderSetting,
        "flowable-layout-base-setting": FlowableLayoutBaseSetting,
        "flowable-layout-form-setting": FlowableLayoutFormSetting,
        "flowable-layout-pro-setting": FlowableLayoutProSetting,
        "flowable-layout-process-design": FlowableLayoutProcessDesign,
    },
})
export default class ProcessDesign extends BaseFunction {
    private isNew = true;
    private isViewXml = false;
    private xmlFlowVisible = false;
    private xmlFlowValue: any = {};
    private isLoading = false;
    private isLoading1 = false;
    private validStep = 0;
    private timer: any = null;
    private activeSelect = "baseSetting";
    private validVisible = false;
    private viewCodeType = "xml";
    private viewTitle = "查看XML";
    private validResult: any = {};
    private validOptions: any = [
        { title: "基础信息", description: "", icon: "", status: "" },
        { title: "审批表单", description: "", icon: "", status: "" },
        { title: "审批流程", description: "", icon: "", status: "" },
        { title: "扩展设置", description: "", icon: "", status: "" },
    ];
    private validComponents: any = ["baseSetting", "formSetting", "processDesign", "proSetting"];

    private commonOptions: EditorConfiguration = {
        theme: "tomorrow-night-eighties", // 主题
        lineNumbers: true, // 显示行号
        smartIndent: true, //智能缩进
        indentUnit: 2, // 智能缩进单元长度为 4 个空格
        autofocus: true,
        spellcheck: true,
        readOnly: true,
        // 代码折叠
        foldGutter: true, // 代码折叠
        lineWrapping: false, //自动换行
        gutters: ["CodeMirror-lint-markers", "CodeMirror-linenumbers", "CodeMirror-foldgutter"],
    };
    private xmlOptions = { mode: "application/xml", ...this.commonOptions };
    private jsonOptions = { mode: "text/javascript", ...this.commonOptions };

    get jsonCode() {
        return JSON.stringify(this.xmlFlowValue.json, null, 2);
    }

    public created() {
        this.closeValiding();
        this.loadInitFrom();
        this.diagramQuery = { title: "流程管理", icon: "settings-outline" };
    }
    public activated() {
        this.activeSelect = "baseSetting";
        this.init();
    }
    public init() {
        this.closeValiding();
        const query: any = this.$route.query;
        if (!this.$tools.isEmpty(query.code)) {
            this.isNew = false;
            this.loadFormInfo(query.code);
            this.diagramQuery = { title: "修改流程模版", icon: "settings-outline" };
        } else {
            this.isNew = true;
            this.loadInitFrom();
            if (!this.$tools.isEmpty(query.group)) this.setup.groupId = parseInt(query.group);
            this.diagramQuery = { title: "创建流程模版", icon: "add-outline" };
        }
    }
    public beforeUnmount() {
        this.closeValiding();
    }
    get setup(): any {
        return this.$store.getters["flowable/design"];
    }
    set setup(design: any) {
        this.$store.dispatch("flowable/setDesign", design).then();
    }
    get diagramQuery(): any {
        return this.$store.getters["flowable/diagramQuery"];
    }
    set diagramQuery(design: any) {
        this.$store.dispatch("flowable/setDiagramQuery", design).then();
    }
    get errTitle() {
        if (this.validResult.finished && !this.validResult.success) {
            return this.validResult.title + ` (${this.validResult.errs.length}项错误) 😥`;
        } else {
            return this.validResult.title;
        }
    }
    get validIcon() {
        if (!this.validResult.finished) {
            return "info";
        } else if (this.validResult.success) {
            return "success";
        } else {
            return "warning";
        }
    }
    public loadInitFrom() {
        this.setup = {
            formId: null,
            formName: "未命名表单",
            logo: {
                icon: "ElementPlus",
                background: "#1e90ff",
            },
            settings: {
                commiter: [],
                admin: [],
                sign: false,
                notify: {
                    types: ["APP"],
                    title: "消息通知标题",
                },
            },
            groupId: undefined,
            formItems: [],
            process: {
                id: "root",
                parentId: null,
                type: "ROOT",
                name: "发起人",
                desc: "任何人",
                props: this.$tools.deepCopy(DefaultNodeProps.ROOT_PROPS),
                children: {},
            },
            remark: "备注说明",
        };
    }
    public stopTimer() {
        if (this.timer) clearInterval(this.timer);
    }
    //查看XML
    protected viewXml() {
        this.isViewXml = true;
        this.validateDesign();
    }
    //预览
    public preview() {
        this.isViewXml = false;
        this.validateDesign();
    }
    //发布
    public publishProcess() {
        this.isViewXml = false;
        this.validateDesign();
    }
    public validateDesign() {
        this.validVisible = true;
        this.validStep = 0;
        this.showValiding();
        this.stopTimer();
        this.timer = setInterval(() => {
            this.validResult.errs = (this.$refs[this.validComponents[this.validStep]] as any).validate();
            if (Array.isArray(this.validResult.errs) && this.validResult.errs.length === 0) {
                this.validStep++;
                if (this.validStep >= this.validOptions.length) {
                    this.stopTimer();
                    this.showValidFinish(true, false);
                }
            } else {
                this.stopTimer();
                this.validOptions[this.validStep].status = "error";
                this.showValidFinish(false, this.getDefaultValidErr());
            }
        }, 300);
    }
    public closeValiding() {
        this.validVisible = false;
        this.validStep = 0;
        this.showValiding();
        this.stopTimer();
    }
    public showValiding() {
        this.validResult = {
            errs: [],
            finished: false,
            success: false,
            title: "检查中...",
            action: "处理",
            desc: "正在检查设置项",
        };
        this.validStep = 0;
        this.validOptions.forEach((op: any) => {
            op.status = "";
            op.icon = "";
            op.description = "";
        });
    }
    public showValidFinish(success: any, err: any) {
        this.validResult.success = success;
        this.validResult.finished = true;
        this.validResult.title = success ? "校验完成 😀" : "校验失败 ";
        const btnName1 = this.isViewXml ? "预览" : "提交";
        const btnName2 = this.isViewXml ? "预 览" : "提 交";
        this.validResult.desc = success ? "设置项校验成功，是否" + btnName1 + "？" : err;
        this.validResult.action = success ? btnName2 : "去修改";
    }
    public getDefaultValidErr() {
        switch (this.validStep) {
            case 0:
                return "请检查基础设置项";
            case 1:
                return "请检查审批表单相关设置";
            case 2:
                return "请检查审批流程，查看对应标注节点错误信息";
            case 3:
                return "请检查扩展设置";
            default:
                return "未知错误";
        }
    }
    public doAfter() {
        if (this.validResult.success) {
            this.doPublish();
        } else {
            this.activeSelect = this.validComponents[this.validStep];
            this.validVisible = false;
        }
    }
    public loadFormInfo(formId: any) {
        this.isLoading = true;
        API.getFlow({ formId })
            .then((rsp: any) => {
                console.log("流程设计：", rsp.data);
                const form = rsp.data;
                form.logo = JSON.parse(form.logo);
                form.settings = JSON.parse(form.settings);
                form.formItems = JSON.parse(form.formItems);
                form.process = JSON.parse(form.process);
                // this.assignProcess(form.process);
                this.setup = form;
            })
            .finally(() => {
                this.isLoading = false;
            });
    }
    // public assignProcess(process: any) {
    //     if (process.type === "APPROVAL") {
    //         process.props = Object.assign({}, DefaultNodeProps.APPROVAL_PROPS, process.props);
    //     } else if (process.type === "CC") {
    //         process.props = Object.assign({}, DefaultNodeProps.CC_PROPS, process.props);
    //     } else if (process.type === "ROOT") {
    //         process.props = Object.assign({}, DefaultNodeProps.ROOT_PROPS, process.props);
    //     } else if (process.type === "CONDITION") {
    //         process.props = Object.assign({}, DefaultNodeProps.CONDITION_PROPS, process.props);
    //     } else if (process.type === "DELAY") {
    //         process.props = Object.assign({}, DefaultNodeProps.DELAY_PROPS, process.props);
    //     } else if (process.type === "TRIGGER") {
    //         process.props = Object.assign({}, DefaultNodeProps.TRIGGER_PROPS, process.props);
    //     }
    //
    //     if (process.hasOwnProperty("children")) {
    //         this.assignProcess(process.children);
    //     }
    //     if (process.hasOwnProperty("branchs")) {
    //         for (let i = 0; i < process.branchs.length; i++) {
    //             this.assignProcess(process.branchs[i]);
    //         }
    //     }
    //     console.log(process);
    // }
    public doPublish() {
        const btnMessage = this.isViewXml ? "查看流程XML模型和JSON配置参数！" : "请确认发布流程！";
        const btnName = this.isViewXml ? "预 览" : "提 交";
        this.$vueAlert({
            title: "提示",
            message: btnMessage,
            closeButtonName: "取 消",
            submitButtonName: btnName,
            zIndex: 4000,
        }).then(() => {
            console.log(this.setup);
            const processNew = JSON.parse(JSON.stringify(this.setup.process));

            //判断条件分支
            this.conditionRecursion(processNew);
            //处理表单权限
            this.formRole(processNew, this.setup.formItems);

            const template = {
                formId: this.setup.formId,
                formName: this.setup.formName,
                logo: JSON.stringify(this.setup.logo),
                settings: JSON.stringify(this.setup.settings),
                groupId: this.setup.groupId,
                formItems: JSON.stringify(this.setup.formItems),
                process: JSON.stringify(processNew),
                remark: this.setup.remark,
            };
            if (this.isViewXml) {
                this.isLoading1 = true;
                API.xmlFlow(template)
                    .then((rsp: any) => {
                        this.xmlFlowValue = rsp.data;
                        this.xmlFlowVisible = true;
                        this.viewTitle = "查看XML";
                        this.viewCodeType = "xml";
                        this.closeValiding();
                    })
                    .finally(() => {
                        this.isLoading1 = false;
                    });
            } else if (this.isNew || this.$tools.isEmpty(this.setup.formId)) {
                this.isLoading1 = true;
                API.createFlow(template)
                    .then((rsp: any) => {
                        this.toast.success("创建流程成功");
                        //this.closeValiding();
                        this.init();
                    })
                    .finally(() => {
                        this.isLoading1 = false;
                    });
            } else {
                this.isLoading1 = true;
                API.modFlow(template)
                    .then((rsp: any) => {
                        this.toast.success("修改流程成功");
                        //this.closeValiding();
                        this.init();
                    })
                    .finally(() => {
                        this.isLoading1 = false;
                    });
            }
        });
    }

    //判断条件分支
    public conditionRecursion(process: any) {
        if (!this.$tools.isEmpty(process)) {
            if (!this.$tools.isEmpty(process.branchs)) {
                process.branchs.map((item: any, i: number) => {
                    item.typeElse = i == process.branchs.length - 1;
                    if (!this.$tools.isEmpty(item.children)) {
                        this.conditionRecursion(item.children);
                    } else {
                        return item;
                    }
                });
            }
            if (!this.$tools.isEmpty(process.children)) {
                this.conditionRecursion(process.children);
            }
        }
    }

    //处理表单权限
    public formRole(process: any, forms: any) {
        if (!this.$tools.isEmpty(process)) {
            if (!this.$tools.isEmpty(process.props) && !this.$tools.isEmpty(process.props.formPerms)) {
                const formItems: any = {};
                for (let i = 0; i < forms.length; i++) {
                    const item: any = forms[i];
                    formItems[item.id] = item.title;
                }
                const formPerms = process.props.formPerms;
                for (let i = 0; i < formPerms.length; i++) {
                    const item: any = formPerms[i];
                    if (formItems[item.id]) item["title"] = formItems[item.id];
                }
            }
            if (!this.$tools.isEmpty(process.branchs)) {
                process.branchs.map((item: any, i: number) => {
                    if (!this.$tools.isEmpty(item.children)) {
                        this.formRole(item.children, forms);
                    }
                });
            }
            if (!this.$tools.isEmpty(process.children)) {
                this.formRole(process.children, forms);
            }
        }
    }
}
