import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_switch = _resolveComponent("el-switch")
  const _component_el_form = _resolveComponent("el-form")
  const _component_el_empty = _resolveComponent("el-empty")

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.form.name !== 'SpanLayout')
      ? (_openBlock(), _createBlock(_component_el_form, {
          key: 0,
          "label-width": "90px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, { label: "表单名称" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  size: "default",
                  clearable: "",
                  modelValue: _ctx.form.title,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.form.title) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.form.name), {
              modelValue: _ctx.form.props,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.form.props) = $event))
            }, null, 8, ["modelValue"])),
            _createVNode(_component_el_form_item, { label: "必填项" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_switch, {
                  modelValue: _ctx.form.props.required,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.form.props.required) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "可打印" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_switch, {
                  modelValue: _ctx.form.props.enablePrint,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.form.props.enablePrint) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_el_empty, {
          key: 1,
          description: "当前组件不支持配置"
        }))
  ]))
}