import { defineAsyncComponent } from "vue";

const Approval = defineAsyncComponent(() => import("./config/ApprovalNodeConfig.vue"));
const Cc = defineAsyncComponent(() => import("./config/CcNodeConfig.vue"));
const Concurrent = defineAsyncComponent(() => import("./config/ConcurrentNodeConfig.vue"));
const Condition = defineAsyncComponent(() => import("./config/ConditionNodeConfig.vue"));
const Trigger = defineAsyncComponent(() => import("./config/TriggerNodeConfig.vue"));
const Delay = defineAsyncComponent(() => import("./config/DelayNodeConfig.vue"));
const FormAuthorityConfig = defineAsyncComponent(() => import("./config/FormAuthorityConfig.vue"));
const Root = defineAsyncComponent(() => import("./config/RootNodeConfig.vue"));

export default {
    Approval,
    Cc,
    Concurrent,
    Condition,
    Trigger,
    Delay,
    FormAuthorityConfig,
    Root,
};
