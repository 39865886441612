import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-42214a2a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-header text-center" }
const _hoisted_2 = { class: "card-header-title" }
const _hoisted_3 = { class: "card-header-button" }
const _hoisted_4 = { class: "card-tools" }
const _hoisted_5 = { class: "btn-group" }

export function render(_ctx, _cache) {
  const _component_svg_icon = _resolveComponent("svg-icon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_svg_icon, {
        size: "md",
        name: _ctx.diagramQuery.icon
      }, null, 8, ["name"]),
      _createTextVNode(" " + _toDisplayString(_ctx.diagramQuery.title) + " (" + _toDisplayString(_ctx.formName) + ") ", 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        type: "button",
        class: _normalizeClass(["btn mr-2", _ctx.btnThemes('baseSetting')]),
        onClick: _cache[0] || (_cache[0] = $event => (_ctx.to('baseSetting')))
      }, "① 基础信息", 2),
      _createElementVNode("button", {
        type: "button",
        class: _normalizeClass(["btn mr-2", _ctx.btnThemes('formSetting')]),
        onClick: _cache[1] || (_cache[1] = $event => (_ctx.to('formSetting')))
      }, "② 审批表单", 2),
      _createElementVNode("button", {
        type: "button",
        class: _normalizeClass(["btn mr-2", _ctx.btnThemes('processDesign')]),
        onClick: _cache[2] || (_cache[2] = $event => (_ctx.to('processDesign')))
      }, "③ 审批流程", 2),
      _createElementVNode("button", {
        type: "button",
        class: _normalizeClass(["btn", _ctx.btnThemes('proSetting')]),
        onClick: _cache[3] || (_cache[3] = $event => (_ctx.to('proSetting')))
      }, "④ 扩展设置", 2)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-themes",
          onClick: _cache[4] || (_cache[4] = $event => (_ctx.viewXml()))
        }, "预览"),
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-themes",
          onClick: _cache[5] || (_cache[5] = $event => (_ctx.publish()))
        }, "发布")
      ])
    ])
  ]))
}