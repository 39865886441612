import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_app_select_level = _resolveComponent("app-select-level")

  return (_openBlock(), _createBlock(_component_app_select_level, {
    ref: "picker",
    levelType: "select",
    levelApi: _ctx.API,
    selectItem: _ctx.requestItem,
    selectFunction: _ctx.submitFunction,
    searchFieldList: _ctx.searchField,
    multiple: _ctx.multiple,
    style: {"z-index":"4000"}
  }, null, 8, ["levelApi", "selectItem", "selectFunction", "searchFieldList", "multiple"]))
}