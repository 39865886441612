import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70da123e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "components-nav" }
const _hoisted_2 = { class: "tool-nav" }
const _hoisted_3 = { class: "work-form" }
const _hoisted_4 = { class: "form" }
const _hoisted_5 = { class: "tip" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "form-header" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "option" }
const _hoisted_10 = {
  key: 0,
  class: "tool-nav-r"
}
const _hoisted_11 = {
  key: 1,
  class: "tip"
}
const _hoisted_12 = {
  key: 2,
  style: {"text-align":"left","padding":"10px"}
}

export function render(_ctx, _cache) {
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_vue_draggable_next = _resolveComponent("vue-draggable-next")
  const _component_el_aside = _resolveComponent("el-aside")
  const _component_RefreshLeft = _resolveComponent("RefreshLeft")
  const _component_el_tooltip = _resolveComponent("el-tooltip")
  const _component_RefreshRight = _resolveComponent("RefreshRight")
  const _component_View = _resolveComponent("View")
  const _component_Iphone = _resolveComponent("Iphone")
  const _component_Monitor = _resolveComponent("Monitor")
  const _component_Close = _resolveComponent("Close")
  const _component_FormDesignRender = _resolveComponent("FormDesignRender")
  const _component_el_main = _resolveComponent("el-main")
  const _component_FormComponentConfig = _resolveComponent("FormComponentConfig")
  const _component_FormRender = _resolveComponent("FormRender")
  const _component_app_dialog = _resolveComponent("app-dialog")
  const _component_el_container = _resolveComponent("el-container")

  return (_openBlock(), _createBlock(_component_el_container, { style: {"height":"100%","background-color":"#f5f6f6"} }, {
    default: _withCtx(() => [
      _createVNode(_component_el_aside, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("span", {
              onClick: _cache[0] || (_cache[0] = $event => (_ctx.libSelect = 0))
            }, "组件库")
          ]),
          _createElementVNode("div", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.baseComponents, (group, i) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "components",
                key: i
              }, [
                _createElementVNode("p", null, _toDisplayString(group.name), 1),
                _createElementVNode("ul", null, [
                  _createVNode(_component_vue_draggable_next, {
                    class: "drag",
                    list: group.components,
                    options: {sort: false},
                    group: { name: 'form', pull: 'clone', put: false },
                    onStart: _cache[1] || (_cache[1] = $event => (_ctx.isStart = true)),
                    onEnd: _cache[2] || (_cache[2] = $event => (_ctx.isStart = false)),
                    clone: _ctx.clone
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.components, (cp, id) => {
                        return (_openBlock(), _createElementBlock("li", { key: id }, [
                          _createVNode(_component_el_icon, { size: "1em" }, {
                            default: _withCtx(() => [
                              (_openBlock(), _createBlock(_resolveDynamicComponent(cp.icon)))
                            ]),
                            _: 2
                          }, 1024),
                          _createElementVNode("span", null, _toDisplayString(cp.title), 1)
                        ]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1032, ["list", "clone"])
                ])
              ]))
            }), 128))
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_el_main, { class: "layout-main" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", null, [
              _createVNode(_component_el_tooltip, {
                class: "item",
                effect: "dark",
                content: "撤销",
                placement: "bottom-start"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_icon, { size: "1em" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_RefreshLeft)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_el_tooltip, {
                class: "item",
                effect: "dark",
                content: "恢复",
                placement: "bottom-start"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_icon, { size: "1em" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_RefreshRight)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_el_tooltip, {
                class: "item",
                effect: "dark",
                content: "预览表单",
                placement: "bottom-start"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_icon, {
                    size: "1em",
                    onClick: _ctx.viewForms
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_View)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_tooltip, {
                class: "item",
                effect: "dark",
                content: "移动端",
                placement: "bottom-start"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_icon, {
                    size: "1em",
                    class: _normalizeClass({'select': _ctx.showMobile}),
                    onClick: _cache[3] || (_cache[3] = $event => (_ctx.showMobile = true))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Iphone)
                    ]),
                    _: 1
                  }, 8, ["class"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_tooltip, {
                class: "item",
                effect: "dark",
                content: "PC端",
                placement: "bottom-start"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_icon, {
                    size: "1em",
                    class: _normalizeClass({'select': !_ctx.showMobile}),
                    onClick: _cache[4] || (_cache[4] = $event => (_ctx.showMobile = false))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Monitor)
                    ]),
                    _: 1
                  }, 8, ["class"])
                ]),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: _normalizeClass({'mobile': _ctx.showMobile, 'pc': !_ctx.showMobile})
            }, [
              _createElementVNode("div", {
                class: _normalizeClass({'bd': _ctx.showMobile})
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass({'form-content': _ctx.showMobile})
                }, [
                  _createElementVNode("div", _hoisted_4, [
                    _withDirectives(_createElementVNode("div", _hoisted_5, "👈 请在左侧选择控件并拖至此处", 512), [
                      [_vShow, _ctx.forms.length === 0 && !_ctx.isStart]
                    ]),
                    _createVNode(_component_vue_draggable_next, {
                      class: "drag-from",
                      list: _ctx.forms,
                      group: "form",
                      options: {animation: 300, chosenClass:'choose', sort:true},
                      onStart: _cache[5] || (_cache[5] = $event => {_ctx.drag = true; _ctx.selectFormItem = null}),
                      onEnd: _cache[6] || (_cache[6] = $event => (_ctx.drag = false))
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.forms, (cp, id) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: id,
                            class: "form-item",
                            onClick: $event => (_ctx.selectItem(cp)),
                            style: _normalizeStyle(_ctx.getSelectedClass(cp))
                          }, [
                            _createElementVNode("div", _hoisted_7, [
                              _createElementVNode("p", null, [
                                (cp.props.required)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, "*"))
                                  : _createCommentVNode("", true),
                                _createTextVNode(_toDisplayString(cp.title), 1)
                              ]),
                              _createElementVNode("div", _hoisted_9, [
                                _createVNode(_component_el_icon, {
                                  size: "2em",
                                  onClick: $event => (_ctx.del(id))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_Close)
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"])
                              ]),
                              _createVNode(_component_FormDesignRender, { config: cp }, null, 8, ["config"])
                            ])
                          ], 12, _hoisted_6))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["list"])
                  ])
                ], 2)
              ], 2)
            ], 2)
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_el_aside, { class: "layout-param" }, {
        default: _withCtx(() => [
          (_ctx.selectFormItem)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createVNode(_component_el_icon, { size: "2em" }, {
                  default: _withCtx(() => [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.selectFormItem.icon), { style: {"margin-right":"5px","font-size":"medium"} }))
                  ]),
                  _: 1
                }),
                _createElementVNode("span", null, _toDisplayString(_ctx.selectFormItem.title), 1)
              ]))
            : _createCommentVNode("", true),
          (!_ctx.selectFormItem || _ctx.forms.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, "😀 选中控件后在这里进行编辑"))
            : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createVNode(_component_FormComponentConfig)
              ]))
        ]),
        _: 1
      }),
      _createVNode(_component_app_dialog, {
        clickClose: "",
        closeFree: "",
        width: "800px",
        showFooter: false,
        border: false,
        title: "表单预览",
        modelValue: _ctx.viewFormVisible,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => ((_ctx.viewFormVisible) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_FormRender, {
            ref: "form",
            forms: _ctx.forms,
            modelValue: _ctx.formData,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.formData) = $event))
          }, null, 8, ["forms", "modelValue"])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}