import { Options } from "vue-class-component";
import OrgPicker from "../../components/OrgPicker/OrgPicker.vue";
import API from "@/api/flowable/template-groups";
import BaseFunction from "@/mixins/BaseFunction";

@Options({
    name: "flowable-setting-base-setting",
    components: { OrgPicker },
})
export default class BaseSetting extends BaseFunction {
    private nowUserSelect: any = null;
    private showIconSelect = false;
    private select: any[] = [];
    private newGroup = "";
    private fromGroup: any[] = [];
    private notifyTypes: any = [
        { type: "APP", name: "应用内通知" },
        { type: "EMAIL", name: "邮件通知" },
        { type: "SMS", name: "短信通知" },
        { type: "WX", name: "微信通知" },
        { type: "DING", name: "钉钉通知" },
    ];
    private colors: any = [
        "#ff4500",
        "#ff8c00",
        "#ffd700",
        "#90ee90",
        "#00ced1",
        "#1e90ff",
        "#c71585",
        "rgba(255, 69, 0, 0.68)",
        "rgb(255, 120, 0)",
        "hsl(181, 100%, 37%)",
        "hsla(209, 100%, 56%, 0.73)",
        "#c7158577",
    ];
    private icons: any = [
        "ElementPlus",
        "DeleteFilled",
        "Tools",
        "Goods",
        "Warning",
        "CirclePlus",
        "CameraFilled",
        "Promotion",
        "Coordinate",
        "Platform",
        "Document",
        "DataAnalysis",
        "Checked",
        "InfoFilled",
    ];
    private rules: any = {
        formName: [{}],
        groupId: [],
    };
    public created() {
        //this.loadIconfont();
    }
    public mounted() {
        this.getGroups();
    }
    get setup(): any {
        return this.$store.getters["flowable/design"];
    }
    set setup(val: any) {
        this.$store.dispatch("flowable/setDesign", val).then();
    }
    public getRule(msg: any) {
        return [{ required: true, message: msg, trigger: "blur" }];
    }
    // public loadIconfont() {
    //     if (iconfont && iconfont.id) {
    //         iconfont.glyphs.forEach((icon) => {
    //             this.icons.push(
    //                 `${iconfont.font_family} ${iconfont.css_prefix_text}${icon.font_class}`
    //             );
    //         });
    //     }
    // }
    public getGroups() {
        API.select({}).then((rsp: any) => {
            this.fromGroup = rsp.data;
        });
    }
    public addGroup() {
        // if (this.newGroup.trim() !== '') {
        //     updateGroup({name: this.newGroup.trim()}, 'post')
        //         .then((rsp) => {
        //             this.$message.success(rsp.data);
        //             this.getGroups();
        //         })
        //         .catch((err) => this.$message.error(err.response.data));
        // }
    }
    public selected(select: any) {
        //this.$set(this.setup.settings, this.nowUserSelect, select);
        this.setup[this.nowUserSelect] = select;
    }
    public selectUser(key: any) {
        this.select = this.setup.settings[key];
        this.nowUserSelect = key;
        (this.$refs.orgPicker as any).modalOpen();
    }
    public validate() {
        (this.$refs.baseSetting as any).validate();
        const err = [];
        if (this.$tools.isEmpty(this.setup.formName)) {
            err.push("表单名称未设置");
        }
        if (this.$tools.isEmpty(this.setup.groupId)) {
            err.push("表单分组未设置");
        }
        if (this.setup.settings.notify.types.length === 0) {
            err.push("审批消息通知方式未设置");
        }
        return err;
    }
}
