import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")
  const _component_form_authority_config = _resolveComponent("form-authority-config")
  const _component_el_tabs = _resolveComponent("el-tabs")

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.name && _ctx.formConfig.length > 0)
      ? (_openBlock(), _createBlock(_component_el_tabs, {
          key: 0,
          modelValue: _ctx.active,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.active) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_tab_pane, {
              label: _ctx.name,
              name: "properties"
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent((_ctx.selectedNode.type||'').toLowerCase()), {
                  config: _ctx.selectedNode.props
                }, null, 8, ["config"]))
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_tab_pane, {
              label: "表单权限设置",
              name: "permissions"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_form_authority_config)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : (_openBlock(), _createBlock(_resolveDynamicComponent((_ctx.selectedNode.type||'').toLowerCase()), {
          key: 1,
          config: _ctx.selectedNode.props
        }, null, 8, ["config"]))
  ]))
}