import requests from "@/utils/axios";

export default {
    // 模块URL
    modulesUrl: "/flowable/process/design",
    // 创建流程
    createFlow(query: any) {
        return requests.post(this.modulesUrl + "/createFlow", query);
    },
    // 更新流程
    modFlow(query: any) {
        return requests.post(this.modulesUrl + "/modFlow", query);
    },
    // 查看XML
    xmlFlow(query: any) {
        return requests.post(this.modulesUrl + "/xmlFlow", query);
    },
    // 获取流程
    getFlow(query: any) {
        return requests.post(this.modulesUrl + "/getFlow", query);
    },
};
