import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache) {
  const _component_FormDesignRender = _resolveComponent("FormDesignRender")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_form = _resolveComponent("el-form")

  return (_openBlock(), _createBlock(_component_el_form, {
    ref: "form",
    class: "process-form",
    "label-position": "top",
    rules: _ctx.rules,
    model: _ctx._value
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.forms, (item, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: item.name + index
        }, [
          (item.name !== 'SpanLayout' && item.name !== 'Description')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 0,
                prop: item.id,
                label: item.title
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FormDesignRender, {
                    ref_for: true,
                    ref: `sub-item_${item.id}`,
                    modelValue: _ctx._value[item.id],
                    "onUpdate:modelValue": $event => ((_ctx._value[item.id]) = $event),
                    mode: "PC",
                    config: item
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "config"])
                ]),
                _: 2
              }, 1032, ["prop", "label"]))
            : (_openBlock(), _createBlock(_component_FormDesignRender, {
                key: 1,
                ref_for: true,
                ref: "span-layout",
                modelValue: _ctx._value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx._value) = $event)),
                mode: "PC",
                config: item
              }, null, 8, ["modelValue", "config"]))
        ], 64))
      }), 128))
    ]),
    _: 1
  }, 8, ["rules", "model"]))
}