import { Options } from "vue-class-component";
import ProcessTree from "./ProcessTree/ProcessTree.vue";
import NodeConfig from "./NodeConfig/NodeConfig.vue";
import BaseFunction from "@/mixins/BaseFunction";
import DefaultNodeProps from "@/modules/flowable/common/process/DefaultNodeProps";
import html2canvas from "html2canvas";

@Options({
    name: "flowable-process-design",
    components: { ProcessTree, NodeConfig },
    watch: {
        /*selectedNode:{
          deep: true,
          handler(node){
            console.log("更新")
            this.$refs["process-tree"].nodeDomUpdate(node)
          }
        }*/
    },
})
export default class ProcessDesign extends BaseFunction {
    private scale = 100;
    private selected: any = {};
    private showInput = false;
    private showConfig = false;

    public activated() {
        this.$store.dispatch("flowable/setDiagramMode", "design").then(() => {});
    }
    public unactivated() {
        this.$store.dispatch("flowable/setDiagramMode", "viewer").then(() => {});
    }

    get setup(): any {
        return this.$store.getters["flowable/design"];
    }
    set setup(val: any) {
        this.$store.dispatch("flowable/setDesign", val).then();
    }

    get selectedNode(): any {
        return this.$store.getters["flowable/selectedNode"];
    }
    set selectedNode(val: any) {
        this.$store.dispatch("flowable/setSelectedNode", val).then();
    }

    public validate() {
        return (this.$refs["process-tree"] as any).validateProcess();
    }
    public nodeSelected(node: any) {
        if (node.type === "APPROVAL") {
            node.props = Object.assign({}, DefaultNodeProps.APPROVAL_PROPS, node.props);
        } else if (node.type === "CC") {
            node.props = Object.assign({}, DefaultNodeProps.CC_PROPS, node.props);
        } else if (node.type === "ROOT") {
            node.props = Object.assign({}, DefaultNodeProps.ROOT_PROPS, node.props);
        } else if (node.type === "CONDITION") {
            node.props = Object.assign({}, DefaultNodeProps.CONDITION_PROPS, node.props);
        } else if (node.type === "DELAY") {
            node.props = Object.assign({}, DefaultNodeProps.DELAY_PROPS, node.props);
        } else if (node.type === "TRIGGER") {
            node.props = Object.assign({}, DefaultNodeProps.TRIGGER_PROPS, node.props);
        }
        // console.log("保存节点", this.selectedNode);
        // console.log("配置节点", node);
        this.showConfig = true;
    }
    public processDownload() {
        html2canvas(document.querySelector(".design ._root"), {
            backgroundColor: "#f5f6f6",
        }).then((canvas) => {
            // 转换成base64格式，这个格式可以挂载到img里，也可以下载下来，也可以打压缩包下载下来
            const base64 = canvas.toDataURL().replace(/^data:image\/(png|jpg);base64,/, "");
            const base64img = `data:image/png;base64,${base64}`;
            // console.log(base64img.value)

            if (window.navigator.msSaveOrOpenBlob) {
                const bStr = atob(base64img.split(",")[1]);
                let n = bStr.length;
                const u8Arr = new Uint8Array(n);
                while (n--) {
                    u8Arr[n] = bStr.charCodeAt(n);
                }
                const blob = new Blob([u8Arr]);
                window.navigator.msSaveOrOpenBlob(blob, "流程图" + "." + "pdf");
            } else {
                // 这里就按照chrome等新版浏览器来处理
                const a = document.createElement("a");
                a.href = base64img;
                a.setAttribute("download", "流程图");
                a.click();
            }
        });
    }
}
