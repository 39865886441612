import { Options } from "vue-class-component";
import components from "@/modules/flowable/common/form/ComponentConfigExport";
import BaseFunction from "@/mixins/BaseFunction";

@Options({
    name: "flowable-setting-form-component-config",
    components,
})
export default class FormComponentConfig extends BaseFunction {
    get form() {
        return this.$store.getters["flowable/selectFormItem"];
    }
    set form(val) {
        this.$store.dispatch("flowable/setSelectFormItem", val).then();
    }
}
