import { defineAsyncComponent } from "vue";

const Approval = defineAsyncComponent(() => import("./nodes/ApprovalNode.vue"));
const Cc = defineAsyncComponent(() => import("./nodes/CcNode.vue"));
const Concurrent = defineAsyncComponent(() => import("./nodes/ConcurrentNode.vue"));
const Condition = defineAsyncComponent(() => import("./nodes/ConditionNode.vue"));
const Trigger = defineAsyncComponent(() => import("./nodes/TriggerNode.vue"));
const Delay = defineAsyncComponent(() => import("./nodes/DelayNode.vue"));
const Empty = defineAsyncComponent(() => import("./nodes/EmptyNode.vue"));
const Root = defineAsyncComponent(() => import("./nodes/RootNode.vue"));
const Node = defineAsyncComponent(() => import("./nodes/Node.vue"));

export default {
    Approval,
    Cc,
    Concurrent,
    Condition,
    Trigger,
    Delay,
    Empty,
    Root,
    Node,
};
