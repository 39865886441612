import baseApi from "@/api/baseApi";
import requests from "@/utils/axios";

export default {
    ...baseApi,
    // 模块URL
    modulesUrl: "/flowable/template/groups",
    // 普通选择器
    select(query: any) {
        return requests.post(this.modulesUrl + "/select", query);
    },
};
